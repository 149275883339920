import React from "react";
import { heroImages } from "../img/hero-carousel";

const CarouselItem = ({ title, description, heroImage, isActive }) => (
  <div className={`carousel-item ${isActive ? "active" : ""}`}>
    <div className="carousel-item-container">
      <img src={heroImage} alt={title} className="carousel-image" />
      <div className="darken"></div>
    </div>
    <div className="carousel-caption">
      <h5>{title}</h5>
      <p>{description}</p>
      <p className="d-none d-sm-block">
        <a className="btn btn-warning my-md-3" href="#contact">
          Contact Us
        </a>
      </p>
    </div>
  </div>
);

const CarouselIndicator = ({ index, isActive }) => (
  <button
    aria-label={`Slide ${index + 1}`}
    className={isActive ? "active" : ""}
    data-bs-slide-to={index}
    data-bs-target="#carouselExampleIndicators"
    type="button"
  ></button>
);

const Carousel = () => {
  const carouselItems = [
    {
      title: "Modern House Design",
      description: "Our expertise brings your dream home to life.",
      heroImage: heroImages.house0,
    },
    {
      title: "Modern House Design. ",
      description: "From Concept to Creation, We Build Your Vision",
      heroImage: heroImages.house1,
    },
    {
      title: "Modern House Design",
      description: "Designing the Future, Brick by Brick",
      heroImage: heroImages.house2,
    },
    {
      title: "Modern House Design",
      description: "Where Innovation Meets Comfort and Style",
      heroImage: heroImages.house3,
    },
    {
      title: "Road Work",
      description: "Premix Carpeting work at Benikot Bhanjyang Site, Tanahaun.",
      heroImage: heroImages.carousel1,
    },
    {
      title: "Drainage Structure",
      description:
        "Upgrading Road Work Project at Sitakothan to Jugu including Retaining and drainage structure work.",
      heroImage: heroImages.carousel2,
    },
    {
      title: "Gabion Work",
      description:
        "Retaining Gabion Works in Dakshinkali-Sisneri Road Improvement Project",
      heroImage: heroImages.carousel3,
    },
    {
      title: "Masonry Work",
      description: "Masonry work at Jugu Road.",
      heroImage: heroImages.carousel4,
    },
  ];

  return (
    <div
      className="carousel slide"
      data-bs-ride="carousel"
      id="carouselExampleIndicators"
    >
      <div className="carousel-indicators">
        {carouselItems.map((_, index) => (
          <CarouselIndicator key={index} index={index} isActive={index === 0} />
        ))}
      </div>
      <div className="carousel-inner">
        {carouselItems.map((item, index) => (
          <CarouselItem key={index} {...item} isActive={index === 0} />
        ))}
      </div>
      <button
        className="carousel-control-prev"
        data-bs-slide="prev"
        data-bs-target="#carouselExampleIndicators"
        type="button"
      >
        <span aria-hidden="true" className="carousel-control-prev-icon"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        data-bs-slide="next"
        data-bs-target="#carouselExampleIndicators"
        type="button"
      >
        <span aria-hidden="true" className="carousel-control-next-icon"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default Carousel;
