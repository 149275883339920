import React from "react";
import { teamImages } from "../img/team";

const TeamMember = ({ image, name, position }) => (
  <div className="col-md-6 col-lg-4">
    <div className="card bg-light">
      <div className="card-body text-center">
        <img src={image} className="rounded-circle mb-3 img-200" alt={name} />
        <h3 className="card-title mb-3">{name}</h3>
        <p className="card-text lead">{position}</p>
      </div>
    </div>
  </div>
);

const Team = () => {
  const teamMembers = [
    {
      image: teamImages.ceo,
      name: "Sujan Adhikari",
      position: "CEO",
    },
    {
      image: teamImages.khem,
      name: "Khem Raj Pathak",
      position: "Managing Director",
    },
    {
      image: teamImages.hemant,
      name: "Hemant Raj Paudyal",
      position: "Sr. Civil Engineer",
    },
    {
      image: teamImages.dipendra,
      name: "Dipendra Shah",
      position: "Civil Engineer",
    },
    {
      image: teamImages.ranjit,
      name: "Ranjit Shah",
      position: "Architecture",
    },
    {
      image: teamImages.saliya,
      name: "Saliya Shrestha",
      position: "Architecture",
    },
    {
      image: teamImages.suresh,
      name: "Suresh Aryal",
      position: "Director / Construction Head",
    },
    {
      image: teamImages.ujjwal,
      name: "Ujjwal Dahal",
      position: "Sub- Engineer",
    },
    {
      image: teamImages.yuman,
      name: "Yuman Shrestha",
      position: "Administration / Account",
    },
  ];

  return (
    <section
      id="team"
      className="bg-primary yellow-gradient text-center p-md-5 py-4"
    >
      <div className="container">
        <div className="text-light">
          <h2>Our Team</h2>
          <p className="lead">
            Our team all have 5+ years working experience in Construction
            Industry.
          </p>
        </div>

        <div className="row mt-3 g-4">
          {teamMembers.map((member, index) => (
            <TeamMember key={index} {...member} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Team;
