import ceo from "./ceo.jpg";
import khem from "./khem.jpg";
import hemant from "./hemant.jpg";
import dipendra from "./dipendra.jpg";
import ranjit from "./ranjit.jpg";
import saliya from "./saliya.jpg";
import suresh from "./suresh.jpg";
import ujjwal from "./ujjwal.jpg";
import yuman from "./yuman.jpg";

export const teamImages = {
  ceo,
  khem,
  hemant,
  dipendra,
  ranjit,
  saliya,
  suresh,
  ujjwal,
  yuman,
};
